// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-om-oss-jsx": () => import("./../../../src/pages/om-oss.jsx" /* webpackChunkName: "component---src-pages-om-oss-jsx" */),
  "component---src-pages-tack-jsx": () => import("./../../../src/pages/tack.jsx" /* webpackChunkName: "component---src-pages-tack-jsx" */),
  "component---src-pages-tjanster-jsx": () => import("./../../../src/pages/tjanster.jsx" /* webpackChunkName: "component---src-pages-tjanster-jsx" */),
  "component---src-templates-service-page-jsx": () => import("./../../../src/templates/service-page.jsx" /* webpackChunkName: "component---src-templates-service-page-jsx" */)
}

